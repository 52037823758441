export default class Fx {
  completedAudio;
  noMatchAudio;
  pairFoundAudio;
  resetAudio;

  constructor(number, devMode) {
    this.completedAudio = new Audio('assets/fx/completed.mp3');
    this.noMatchAudio = new Audio('assets/fx/nomatch.mp3');
    this.pairFoundAudio = new Audio('assets/fx/pair-found.mp3');
    this.resetAudio = new Audio('assets/fx/reset.mp3');
  }
}
