export default class ScoreManager {
  url;
  scores;

  rqScores(method, data) {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;

    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4) {
        const highscoreElement = document.getElementById("highscores");
        while (highscoreElement.firstChild) {
          highscoreElement.removeChild(highscoreElement.firstChild);
        }

        JSON.parse(this.responseText).response.forEach(data => {
          const liElement = document.createElement("li");
          liElement.innerText = `${data.name}: ${Array(
            4 - data.score.toString().length
          ).join("\xa0") + data.score} seconds`;
          highscoreElement.append(liElement);
        });
        highscoreElement.style.visibility = "visible";
      }
    });

    xhr.open(method, this.url);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.send(JSON.stringify(data));
  }

  displayScores = () => {
    this.rqScores("GET", {});
  };

  addScore = (score, name) => {
    this.rqScores("POST", { score: score, name: name });
  };

  constructor() {
    this.url = "https://scores.resomag.schapira.dev";
  }
}
