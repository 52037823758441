export default class Card {
  number;
  confirmed;
  faceUp;
  htmlNode;

  flipUp = () => {
    this.faceUp = true;
    this.htmlNode.setAttribute("aria-pressed", "true");
    this.htmlNode.classList.add("face-up");
  };

  flipDown = () => {
    this.faceUp = false;
    this.htmlNode.setAttribute("aria-pressed", "false");
    this.htmlNode.classList.remove("face-up");
  };

  onFlip = (handler, game) => {
    const card = this;
    const cardHandling = () => {
      if (!card.confirmed && !card.faceUp) {
        handler(card, game);
      }
    };
    this.htmlNode.addEventListener("click", cardHandling, false);
    this.htmlNode.addEventListener(
      "keyup",
      () => {
        if (event.keyCode === 13) {
          event.preventDefault();
          cardHandling();
        }
      },
      false
    );
  };

  getDOMFragment = () => {
    let cardFrontElement = document.createElement("div");
    cardFrontElement.className = "face face--front";
    if (this.devMode) {
      cardFrontElement.innerText = this.number;
    }

    let cardBackElement = document.createElement("div");
    cardBackElement.className = "face face--back";
    cardBackElement.dataset.number = this.number;

    let cardElement = document.createElement("div");
    cardElement.setAttribute("alt", `Card number ${this.number}`);
    cardElement.setAttribute("role", "button");
    cardElement.setAttribute("tabindex", "0");
    cardElement.setAttribute("aria-pressed", "false");
    cardElement.className = "card";

    cardElement.append(cardFrontElement, cardBackElement);

    return cardElement;
  };

  constructor(number, devMode) {
    this.number = number;
    this.confirmed = false;
    this.faceUp = false;
    this.devMode = devMode;
    this.htmlNode = this.getDOMFragment(this.devMode);
  }
}
