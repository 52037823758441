import Card from "./card";
import Fx from "./fx";
import ScoreManager from "./scoreManager";
import { arrayShuffle } from "./utils";

export default class MatchingGame {
  cards;
  allocatedTime;
  remainingTime;
  candidate1;
  candidate2;
  fx;
  flipTO;
  timerTO;
  started;
  scoreManager;

  emptyBoardgame = () => {
    const boardgame = document.getElementById("boardgame");
    while (boardgame.firstChild) {
      boardgame.removeChild(boardgame.firstChild);
    }
  };

  createCards() {
    let newCard = [];
    if (!("content" in document.createElement("template"))) return;

    for (var i = 0; i < this.nbPairs * 2; i++) {
      let number = i % this.nbPairs;
      newCard.push(new Card(number, this.devMode));
    }

    if (this.devMode) {
      console.log("Cards created.");
    }

    this.cards = newCard;
  }

  shuffleCards() {
    this.cards = arrayShuffle(this.cards);
    if (this.devMode) {
      console.log("Cards shuffled.");
    }
  }

  displayCards() {
    const boardgame = document.getElementById("boardgame");
    const game = this;
    this.cards.forEach(card => {
      card.htmlNode = boardgame.appendChild(card.htmlNode);
      card.onFlip(this.flipACard, game);
    });
    if (this.devMode) {
      console.log("Cards displayed, face down.");
    }
  }

  startGame = () => {
    game.started = true;

    this.updateTimer();
    clearInterval(this.timerTO);
    this.timerTO = setInterval(this.updateTimer, 1000, this);

    if (this.devMode) {
      console.log("Timer started.");
    }
  };

  updateTimer(game) {
    game = game || this;
    game.remainingTime -= 1;
    document
      .getElementById("game")
      .style.setProperty("--remaining", game.remainingTime);

    if (!game.started || game.remainingTime <= 0) {
      clearInterval(game.timerTO);
      game.resetBoardgame();
    }
  }

  checkPair() {
    if (this.devMode) {
      console.group("Check pair:");
      console.info("Candidate #1: %f", this.candidate1.number);
      console.info("Candidate #2: %f", this.candidate2.number);
      console.groupEnd();
    }

    if (this.candidate1.number == this.candidate2.number) {
      this.fx.pairFoundAudio.play();
      if (this.devMode) {
        console.info("Pair #%s is found!", this.candidate1.number);
      }
      this.candidate1.confirmed = this.candidate2.confirmed = true;
      this.candidate1 = this.candidate2 = null;
      this.checkCompleted();
      return true;
    } else {
      this.fx.noMatchAudio.play();
      this.flipTO = setTimeout(this.resetPair, 2000, this);
      return false;
    }
  }

  checkCompleted() {
    const completed = this.cards.reduce((success, card) => {
      return (success *= card.confirmed);
    }, true);

    if (completed) {
      clearInterval(this.timerTO);
      this.fx.completedAudio.play();
      const time = this.allocatedTime - this.remainingTime;
      setTimeout(
        game => {
          var name = prompt(
            `Game completed in ${time}! What's your name (3 characters)?`
          );
          if (name) {
            game.scoreManager.addScore(time, name.slice(0, 3).toUpperCase());
          }
        },
        1000,
        game
      );
    }
  }

  resetPair(game) {
    game = game || this;
    if (game.candidate1) {
      game.candidate1.flipDown();
      game.candidate1 = null;
    }
    if (game.candidate2) {
      game.candidate2.flipDown();
      game.candidate2 = null;
    }
  }

  flipACard(card, game) {
    game = game || this;

    if (!game.started) {
      game.startGame();
    }

    clearTimeout(game.flipTO);
    if (!game.candidate1) {
      card.flipUp();
      game.candidate1 = card;
    } else if (!game.candidate2) {
      card.flipUp();
      game.candidate2 = card;
      game.checkPair();
    } else {
      game.resetPair(game);
      game.flipACard(card, game);
    }
  }

  resetBoardgame = () => {
    game.started = false;

    this.remainingTime = this.allocatedTime;
    const gameElement = document.getElementById("game");
    gameElement.style.setProperty("--allocated", this.allocatedTime);
    gameElement.style.setProperty("--remaining", this.remainingTime);

    if (this.devMode) {
      console.group("Boardgame initialization");
    }

    this.fx.resetAudio.play();

    this.emptyBoardgame();
    this.createCards();
    this.shuffleCards();
    this.displayCards();

    if (this.devMode) {
      console.groupEnd();
    }
  };

  constructor(nbPairs, allocatedTime, devMode) {
    this.nbPairs = nbPairs;
    this.devMode = devMode;
    this.allocatedTime = allocatedTime;
    this.fx = new Fx();
    this.scoreManager = new ScoreManager();
    this.scoreManager.displayScores();
    this.resetBoardgame();
  }
}
